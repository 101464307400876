/**
 * 检查用户是否是第一次上传地址信息。
 * 只有第一次需要上传，后续不用传了
 * 其他时候根据是否有优惠券id来告诉后台是否要默认选中优惠券
 *
 * 不放在redux原因：
 * 1. 没有必要，放到redux会使获取、修改流程冗余
 * 2. 这不是组件间共享的数据，只是类似于某种 feature flag
 */
export let isLocationUploaded = false

export function doneLocationUploaded() {
  isLocationUploaded = true
}
/**
 * 每次重新进入订单页面 需要重置是否第一次获取
 * case 退出换账号场景
 */
export function resetIsLocationUploaded() {
  isLocationUploaded = false
}
