/**
 * 检查用户是否是第一次获取订单信息。
 * 第一次获取订单信息时，告诉后台默认选中一张优惠券
 * 其他时候根据是否有优惠券id来告诉后台是否要默认选中优惠券
 *
 * 不放在redux原因：
 * 1. 没有必要，放到redux会使获取、修改流程冗余
 * 2. 这不是组件间共享的数据，只是类似于某种 feature flag
 */
export let isFirstGetOrderInfo = true

export function doneFirstGetOrderInfo() {
  isFirstGetOrderInfo = false
}
/**
 * 每次重新进入订单页面 需要重置是否第一次获取
 * case 退出换账号场景
 */
export function resetIsFirstGetOrderInfo() {
  isFirstGetOrderInfo = true
}
