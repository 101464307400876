export default {
  // 确认下单页
  page_view: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
  },
  // 确认下单页登出
  return: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030101',
    Aku_element_name: 'return',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'confirm order',
    Aku_position_id: '01',
  },
  // 确认下单页点击优惠券
  click_coupon: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030102',
    Aku_element_name: 'click coupon',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'confirm order',
    Aku_position_id: '02',
  },
  // 优惠券弹窗展示
  coupon_popup: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030201',
    Aku_element_name: 'coupon-popup',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'coupon',
    Aku_position_id: '01',
  },
  // 优惠券点击关闭
  coupon_close: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030202',
    Aku_element_name: 'coupon-close',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'coupon',
    Aku_position_id: '02',
  },
  // 点击切换优惠券
  select_coupon: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030203',
    Aku_element_name: 'select coupon',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'coupon',
    Aku_position_id: '03',
  },
  // 点击优惠券兑换码输入框
  click_coupon_code_box: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030204',
    Aku_element_name: 'click coupon code box',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'coupon',
    Aku_position_id: '04',
  },
  // 优惠券兑换码输入
  input_coupon_code: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030205',
    Aku_element_name: 'input coupon code',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'coupon',
    Aku_position_id: '05',
  },
  // 点击切换首付比例
  select_payment_ratio: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030103',
    Aku_element_name: 'select payment ratio',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'confirm order',
    Aku_position_id: '03',
  },
  // 点击确认下单页协议
  click_to_confirm_agreement: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030104',
    Aku_element_name: 'click to confirm agreement',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'confirm order',
    Aku_position_id: '04',
  },
  // 点击确认下单页下单
  confirm_payment: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030105',
    Aku_element_name: 'confirm payment',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'confirm order',
    Aku_position_id: '05',
  },
  // 下单请求安全组件
  confirm_operation: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030116',
    Aku_element_name: 'operation',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'confirm',
    Aku_position_id: '16',
  },
  // 确认下单页-支付密码弹框-曝光
  payment_popup: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030301',
    Aku_element_name: 'payment-popup',
    Aku_moudle_id: '03',
    Aku_moudle_name: 'enter payment password ',
    Aku_position_id: '01',
  },
  // 支付密码弹框点击关闭
  password_close: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030302',
    Aku_element_name: 'close',
    Aku_moudle_id: '03',
    Aku_moudle_name: 'enter payment password ',
    Aku_position_id: '02',
  },
  // 支付密码弹框点击输入框
  click_password_box: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030303',
    Aku_element_name: 'click password box',
    Aku_moudle_id: '03',
    Aku_moudle_name: 'enter payment password ',
    Aku_position_id: '03',
  },
  // 支付密码输入框输入密码
  input_password: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030304',
    Aku_element_name: 'input password',
    Aku_moudle_id: '03',
    Aku_moudle_name: 'enter payment password ',
    Aku_position_id: '04',
  },
  // 支付密码弹框点击忘记密码
  forget_password: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030305',
    Aku_element_name: 'forget password',
    Aku_moudle_id: '03',
    Aku_moudle_name: 'enter payment password ',
    Aku_position_id: '05',
  },
  // 支付密码弹框点击确认
  password_confirm: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030306',
    Aku_element_name: 'confirm',
    Aku_moudle_id: '03',
    Aku_moudle_name: 'enter payment password ',
    Aku_position_id: '06',
  },
  // 支付密码校验通过
  password_passed: {
    Aku_page_id: 'openpay03',
    Aku_element_id: 'openpay030307',
    Aku_element_name: 'password passed',
  },
  // 短信验证码弹框-曝光
  verification_popup: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030401',
    Aku_element_name: 'verification-pop up',
    Aku_moudle_id: '04',
    Aku_moudle_name: 'enter verification code',
    Aku_position_id: '01',
  },
  // 短信验证码弹框点击发送验证码
  verification_code_send: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030402',
    Aku_element_name: 'send',
    Aku_moudle_id: '04',
    Aku_moudle_name: 'enter verification code',
    Aku_position_id: '02',
  },
  // 短信验证码弹框点击关闭
  verification_code_close: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030403',
    Aku_element_name: 'close',
    Aku_moudle_id: '04',
    Aku_moudle_name: 'enter verification code',
    Aku_position_id: '03',
  },
  // 短信验证码弹框点击关闭
  verification_code_confirm: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030404',
    Aku_element_name: 'confirm',
    Aku_moudle_id: '04',
    Aku_moudle_name: 'enter verification code',
    Aku_position_id: '04',
  },
  // 点击兑换按钮
  code_exchange_click: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030206',
    Aku_element_name: 'redeem',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'coupon',
    Aku_position_id: '06',
  },
  // 兑换按钮成功
  code_exchange_success: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030207',
    Aku_element_name: 'success',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'coupon',
    Aku_position_id: '07',
  },
  // 兑换按钮成功
  code_exchange_fail: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030208',
    Aku_element_name: 'fail',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'coupon',
    Aku_position_id: '08',
  },
  // 首次下单用户确认还款日-点击选择
  comfirm_repayment_date: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030405',
    Aku_element_name: 'confirm repayment date',
    Aku_moudle_id: '04',
    Aku_moudle_name: '',
    Aku_position_id: '05',
  },
  // 点击登出账号-确认退出
  logout_yes: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030106',
    Aku_element_name: 'yes',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'coupon',
    Aku_position_id: '06',
  },
  // 点击登出账号-取消退出
  logout_no: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030107',
    Aku_element_name: 'no',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'coupon',
    Aku_position_id: '07',
  },
  // 点击查看条款
  click_terms: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030209',
    Aku_element_name: 'click terms',
    Aku_moudle_id: '02',
    Aku_moudle_name: '',
    Aku_position_id: '09',
  },
  // 条款弹窗-关闭
  close: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030210',
    Aku_element_name: 'close',
    Aku_moudle_id: '02',
    Aku_moudle_name: '',
    Aku_position_id: '10',
  },
  choose_period: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030109',
    Aku_element_name: 'payment plan',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'confirm',
    Aku_position_id: '09',
  },
  request_installment: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030110',
    Aku_element_name: 'request',
    Aku_moudle_id: '05',
    Aku_moudle_name: 'request',
    Aku_position_id: '01',
  },
  // 展开分期计划
  expand_openpay030113: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030113',
    Aku_element_name: 'expand',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'confirm',
    Aku_position_id: '13',
  },
  // 收起分期计划
  collapse_openpay030114: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030114',
    Aku_element_name: 'collapse',
    Aku_moudle_id: '01',
    Aku_moudle_name: '',
    Aku_position_id: '14',
  },
  // 点击查看还款计划
  repayment_plan_openpay030108: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030108',
    Aku_element_name: 'repayment plan',
    Aku_moudle_id: '01',
    Aku_moudle_name: '',
    Aku_position_id: '08',
  },
  // 小额免密协议勾选/曝光
  agreement_openpay030112: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030112',
    Aku_element_name: 'agreement',
    Aku_moudle_id: '01',
    Aku_moudle_name: '',
    Aku_position_id: '12',
  },
  // 支付按钮上方积分展示
  pay_button_point_view: {
    Aku_page_id: 'openpay03',
    Aku_page_name: 'openpay confirm order page',
    Aku_element_id: 'openpay030115',
    Aku_element_name: 'point',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'confirm ',
    Aku_position_id: '15',
  },
}
