/** IP组件operationId key */
export const SEV_OPERATION_ID_KEY = 'aku_sev_operation_id'

/** IP组件验证状态的 key，"1" 是通过，"0" 是不通过 */
export const SEV_IP_VALIDATE_STATUS_KEY = 'aku_sev_ip_validate_status'

/** 是否从IP组件结果页跳转到订单页的标志 key， "1" 是， "0" 否 */
export const IP_VERIFY_RESULT_KEY = 'IP_VERIFY_RESULT'

/** 进入IP组件之前缓存的页面参数key */
export const IP_VERIFY_SERACH_KEY = 'IP_VERIFY_SERACH'

/** 进入IP组件之前缓存的订单确认页数据 */
export const IP_VERIFY_PAGE_CACHE_KEY = 'IP_VERIFY_PAGE_CACHE'
