import { fromJS } from 'immutable'
import { actions } from './index'
import { helpers } from '@common'
import axios from 'axios'
import md5 from 'md5'
import { globalActionCreator } from '~/common/globalActions'
import {
  limitCapacityMap,
  SCENARIO_NUMBER_MAP,
  SECURITY_VERIFICATION_BIZ_TYPE,
} from '~/common/macro'
import Eruda from '@common/helpers/Eruda'

import Logger, * as elementId from '~/config/logger'
import { store } from '~/store'
import { push, replace } from 'connected-react-router'

import { Translate } from '~/config/translate'
import loggerConfig from '../logger.config'

import translateDeviceData from './translateDeviceData'

import React from 'react'
import styles from '../style.m.scss'
import {
  PaymentResultStatusNumber,
  statusNumberToDisplayStatus,
  PaymentResultStatus,
} from '~/views/paymentResult/common'
import _ from 'lodash'
import BizTracker from '~/common/tracker/bizTracker'
import {
  doneFirstGetOrderInfo,
  isFirstGetOrderInfo,
} from '~/views/orderDetails/isFirstGetOrderInfo'
import { bridge } from '~/common/bridge'
import { getSmDeviceData } from '@/components/device-sdk'
import { getDeviceType } from '@common/helpers/getDeviceType'
import {
  IP_VERIFY_RESULT_KEY,
  IP_VERIFY_PAGE_CACHE_KEY,
  SEV_IP_VALIDATE_STATUS_KEY,
  SEV_OPERATION_ID_KEY,
  IP_VERIFY_SERACH_KEY,
} from '../common/constant'
import {
  doneLocationUploaded,
  isLocationUploaded,
} from '~/views/orderDetails/isLocationUploaded'

const { get, storage, URL } = helpers

const __isPc = () => window.location.href.includes('/pc/')

// HACK 临时转换解决线上问题
const tranformDownpaymentRate = (rate) => {
  if (typeof rate === 'number') {
    if (rate === 0) {
      return '0.0'
    } else {
      return rate.toFixed(2)
    }
  } else {
    return rate
  }
}

/*
 * 保存进入页面的时间点
 */
export const goSaveEnterTime = (enterTime) => ({
  type: actions.SAVE_ENTER_TIME,
  enterTime,
})

/*
 * 保存点击下单按钮的时间点
 */
export const goSaveClickPayTime = (clickPayTime) => ({
  type: actions.SAVE_CLICK_PAY_TIME,
  clickPayTime,
})

/*
 * 显示/隐藏全款选项
 * 只有当被风控拒绝或者只能全款的情况下，才放出该选项
 */
const __toggleFullpaymentDisplay = (showFullpay) => ({
  type: actions.TOGGLE_FULL_PAYMENT,
  showFullpay,
})

/*
 * 设置只能全款支付，分期下单被拒
 */
export const goSetFullPayOnly = (fullPayOnly) => ({
  type: actions.SET_FULL_PAYMENT,
  fullPayOnly,
})

/*
 * 设置首付比例选项的显示隐藏
 * 部分0首付的用户会隐藏
 */
const __toggleDownpaymentRateDisplay = (show) => ({
  type: actions.TOGGLE_DOWNPAYMENT_RATE_DISPLAY,
  show,
})

/*
 * 将新订单信息添加到state中
 */
export const __addNewStateFromOrder = (orderState) => ({
  type: actions.ADD_NEW_STATE_FROM_ORDER,
  orderState,
})

/*
 * 更新分期计划到state
 */
const __updateNewPlanToState = (orderPlan) => ({
  type: actions.ADD_NEW_PLAN_TO_STATE,
  orderPlan,
})

/*
 * 设置首付比例
 * downpaymentRate {string} 首付比例值
 * curPaymentStrategy {immutable list} 当前首付比例下的分期策略
 */
export const setDownpaymentRate = (downpaymentRate, curPaymentStrategy) => ({
  type: actions.SET_DOWNPAYMENT_RATE,
  downpaymentRate,
  curPaymentStrategy,
})

export const goToggleCouponCodeForm = (show) => ({
  type: actions.TOGGLE_COUPONCODE_FORM,
  show,
})

export const goSetCouponCode = (code) => ({
  type: actions.SET_COUPON_CODE,
  code,
})

export const goSetCouponErrorText = (errText) => ({
  type: actions.SET_COUPON_ERR_TEXT,
  errText,
})

// 设置支付密码校验流程的数据
export const setPasswordVerificationData = (paymentVerificationData) => ({
  type: actions.SET_PAYMENT_VERIFICATION_DATA,
  paymentVerificationData,
})

/*
 * 显示/隐藏提示框
 * show {boolean} true表示显示，false表示隐藏
 * dialogType {String} 提示框类型
 */
export const goToggleBasicDialog = ({ show, dialogType, content = '' }) => ({
  type: actions.TOGGLE_BASIC_DIALOG,
  basicDialog: { show, dialogType, content },
})

/*
 * 显示/隐藏slideup panel
 * show {boolean} true表示显示，false表示隐藏
 * name {string} slideup内的组件名
 */
export const goToggleBasicPopup = (show, name) => ({
  type: actions.TOGGLE_BASIC_POPUP,
  show,
  name,
})

// 设置可领取积分信息
const setPointsInfo = (pointsInfo) => ({
  type: actions.SET_POINTS_INFO,
  pointsInfo,
})

// 发送忘记密码短信
export const goSetForgetPasswordSMS = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const appId = state.getIn(['main', 'appId'])
    const refNo = state.getIn(['main', 'refNo'])
    const phoneNumber = URL.getParam('phone')
    const sign = state.getIn(['main', 'sign'])

    if (!phoneNumber) {
      return
    }

    await axios.post('/api/json/public/openpay/forget/password', {
      appId,
      refNo,
      phoneNumber,
      sign,
    })
  }
}

/*
 * 选择分期数
 * period {string} 选择的分期数
 * monthlyPay {string} 月付
 * downPayment {string} 首付
 * curPayment {string} 当前应付款金额
 */
export const goChoosePeriod = (
  period,
  monthlyPay,
  downPayment,
  curPayment,
  lastMonthlyPay,
) => ({
  type: actions.CHOOSE_PERIOD,
  period,
  monthlyPay,
  downPayment,
  curPayment,
  lastMonthlyPay,
})

// 保存当前选择的couponId
export const setCurrentCouponId = (curCouponId) => ({
  type: actions.SET_CUR_COUPON_ID,
  curCouponId,
})

const __setOrderDetailBanner = (orderDetailBanner) => ({
  type: actions.SET_ORDER_DETAIL_BANNER,
  orderDetailBanner,
})

/*
 * 选择分期逻辑
 * period {String}
 * monthlyPay {String}
 * downpament {String}
 * lastMonthlyPay {string}
 */
export const doChoosePeriodLogic = (
  period,
  monthlyPay,
  downpayment,
  lastMonthlyPay,
) => {
  return async (dispatch) => {
    const state = store.getState()
    const translate = Translate.getInstance().translate
    const saLogger = Logger.getInstance()
    const riskInstallmentResult = state.getIn([
      'orderDetail',
      'plans',
      'riskInstallmentResult',
    ])
    const downpaymentRate = state.getIn(['orderDetail', 'downpaymentRate'])
    const curCouponId = state.getIn(['orderDetail', 'curCouponId'])
    const curPeriod = state.getIn(['orderDetail', 'curPeriod'])

    if (period === '0') {
      saLogger.setOpenpayRiskStatus('全款')
    } else {
      if (downpaymentRate !== '0.0') {
        saLogger.setOpenpayRiskStatus('有首付')
      } else {
        saLogger.setOpenpayRiskStatus('0首付')
      }
    }

    switch (riskInstallmentResult) {
      case limitCapacityMap.LIMIT_OUT_CREDIT:
        dispatch(limitOutCreditAction())
        break

      case limitCapacityMap.LIMIT_NO_CREDIT_NEW_USER:
        function goApplyLimits() {
          dispatch(
            push({
              pathname: '/applyLimits/1/1',
              search: window.location.search,
            }),
          )
        }
        dispatch(
          globalActionCreator.toggleAlertDialog(true, {
            title: translate('获取额度_dialog_title'),
            content: translate('获取额度_dialog_content'),
            footer: [
              {
                text: translate('申请分期额度'),
                // 点击按钮后前往H5授信页
                onPress: goApplyLimits,
              },
            ],
          }),
        )
        break

      case limitCapacityMap.LIMIT_REJECT_BY_RISK:
        dispatch(
          globalActionCreator.toggleToast(true, translate('您暂无分期权限')),
        )
        break

      default:
        // BNPL需求：切换了分期就要重新定价
        if (String(curPeriod) !== String(period)) {
          dispatch(
            goGetOrderInfo(period, null, null, downpaymentRate, curCouponId),
          )
        }

        const curPayment = downpayment
        dispatch(
          goChoosePeriod(
            period,
            monthlyPay,
            downpayment,
            curPayment,
            lastMonthlyPay,
          ),
        )
        saLogger.onPageClick(elementId.CHOOSE_PERIOD, period)
        break
    }
  }
}

/*
 * 设置首付比例
 * rate {String} 首付比例
 * defaultPayment {immutable list} 当前首付比例下的分期策略
 * period {string} 分期数
 * orderPrice {Number} 订单价格
 * shouldGetOrderInfoAgain {Boolean}
 * defaultSelectedPeriods {Number} 定价接口返回的默认选中期数
 */
export const goSetDownpaymentRate = (
  rate,
  defaultPayment,
  period,
  orderPrice,
  shouldGetOrderInfoAgain,
) => {
  return (dispatch) => {
    const state = store.getState()

    if (shouldGetOrderInfoAgain) {
      const curCouponId = state.getIn(['orderDetail', 'curCouponId'])
      dispatch(
        goGetOrderInfo(
          null,
          null,
          null,
          rate,
          curCouponId ? curCouponId : null,
          true,
        ),
      )
      return
    }

    if (!defaultPayment) {
      const payments = state.getIn(['orderDetail', 'plans', 'payments'])
      const isSingleFullPayment = state.getIn([
        'orderDetail',
        'plans',
        'isSingleFullPayment',
      ])
      const riskInstallmentResult = state.getIn([
        'orderDetail',
        'plans',
        'riskInstallmentResult',
      ])

      // 将全款合并
      defaultPayment = payments.get(rate)
      // defaultPayment =
      //   rate !== '1.0'
      //     ? payments.get(rate).push(payments.get('1.0').get(0))
      //     : payments.get(rate)

      //用户额度充足但当前订单不能选全款，则隐藏全款选项
      const hideFullpayForNiceUser =
        riskInstallmentResult === limitCapacityMap.LIMIT_SUFFICIENT &&
        isSingleFullPayment

      if (rate !== '1.0' && hideFullpayForNiceUser) {
        dispatch(__toggleFullpaymentDisplay(false))
      }
    }

    //计算最长分期 默认是 最小比例下的最长分期
    const defaultPaymentjs = defaultPayment.toJS()
    period = String(
      period
        ? period
        : _.last(
            defaultPaymentjs.filter(
              (item) =>
                item.downPaymentRate === defaultPaymentjs[0].downPaymentRate,
            ),
          )?.periods,
    )

    // 当前分期数下的payment
    const curPeriodPayment = defaultPayment
      .filter((item) => String(item.get('periods')) === period)
      .get(0)
    const downPayment = curPeriodPayment.get('downPayment')
    const monthlyPay = curPeriodPayment.get('monthlyPay')
    const curPayment = downPayment
    const lastMonthlyPay = curPeriodPayment.get('lastMonthlyPay')

    dispatch(setDownpaymentRate(rate, defaultPayment))
    dispatch(
      goChoosePeriod(
        period,
        monthlyPay,
        downPayment,
        curPayment,
        lastMonthlyPay,
      ),
    )

    //如果有折扣价格，则用折扣价计算免息
    const discountPrice = state.getIn([
      'orderDetail',
      'plans',
      'discountPriceOfPeriods',
      period,
    ])

    dispatch(
      __calculatePeriodOfInterestFree(
        discountPrice ? discountPrice : orderPrice,
        defaultPayment,
      ),
    )

    const saLogger = Logger.getInstance()
    if (rate === '1.0') {
      saLogger.setOpenpayRiskStatus('全款')
    } else if (rate === '0.0') {
      saLogger.setOpenpayRiskStatus('0首付')
    } else {
      saLogger.setOpenpayRiskStatus('有首付')
    }
  }
}

/*
 * 计算出免息的分期数
 * 规则：（分期数*月供）+首付=订单价格 ==> 免息
 * nowPrice {Number} 当前订单的价格（如果有折扣，则为折扣后的价格）
 */
const __calculatePeriodOfInterestFree = (nowPrice, defaultPayment) => {
  return (dispatch) => {
    const interestFreeMap = defaultPayment
      .filter((item) => {
        return (
          item.get('periods') > 0 &&
          item.get('periods') * item.get('monthlyPay') +
            item.get('downPayment') ===
            nowPrice
        )
      })
      .get('0')

    dispatch(
      __savePeriodOfInterestFree(
        interestFreeMap && interestFreeMap.size > 0
          ? interestFreeMap.get('periods')
          : null,
      ),
    )
  }
}

/*
 * 保存免息分期数
 */
const __savePeriodOfInterestFree = (period) => ({
  type: actions.SAVE_INTEREST_FREE_PERIOD,
  period,
})

/*
 * 跳转到继续支付
 * payInfo {Object} 调用支付网关所需要的信息
 * installmentInfo {Object} 分期信息
 */
const __jumpToPayContinue = (payInfo, installmentInfo, callbackPageUrl) => {
  return (dispatch) => {
    dispatch(
      push({
        pathname: `./pay-continue`,
        state: { payInfo, installmentInfo, callbackPageUrl },
        search: `${window.location.search}`,
      }),
    )
  }
}

/*
 * 跳转到继续支付
 * payInfo {Object} 调用支付网关所需要的信息
 * installmentInfo {Object} 分期信息
 */
const __jumpToPaySuccess = (installmentInfo, callbackPageUrl) => {
  const curPeriod = installmentInfo.periods
  const price = installmentInfo.amount
  return (dispatch) => {
    dispatch(
      push({
        pathname: `./paymentResult`,
        state: { curPeriod, price, callbackPageUrl },
        search: `${window.location.search}&status=success`,
      }),
    )
  }
}

/*
 * 获取订单信息后处理神策埋点
 * riskInstallmentResult {Number} 风控返回的用户状态吧
 */
const handleDataReportAfterGetOrderInfo = (
  riskInstallmentResult,
  defaultPayment,
) => {
  const saLogger = Logger.getInstance()

  saLogger.setOpenpayRiskReturn(riskInstallmentResult)
  if (riskInstallmentResult === limitCapacityMap.LIMIT_SUFFICIENT) {
    if (
      defaultPayment
        .map((item) => item.get('downPaymentRate'))
        .reduce((x, y) => (x > y ? x : y)) > 0
    ) {
      saLogger.setOpenpayPayStatus('有首付')
    } else {
      saLogger.setOpenpayPayStatus('0首付')
    }
  } else if (
    riskInstallmentResult === limitCapacityMap.LIMIT_OUT_CREDIT ||
    riskInstallmentResult === limitCapacityMap.LIMIT_REJECT_BY_RISK
  ) {
    saLogger.setOpenpayPayStatus('全款')
  } else {
    saLogger.setOpenpayPayStatus('全款')
  }
  saLogger.onPageView(elementId.PAGE_ORDER_CONFIRM)
}

/*
 * 计算重要的订单信息
 * orderPlan {Object} 分期计划
 */
const computeCrucialDataFromOrder = (
  orderPlan,
  prevPeriod,
  fullPayOnly,
  downPaymentRate,
) => {
  const payments = get(orderPlan, 'payments')
  const orderPrice = get(orderPlan, 'price')
  const riskInstallmentResult = get(orderPlan, 'riskInstallmentResult')

  let defaultPayment = _.flatMap(payments, (value, key) => {
    // item.downPaymentRate 是 number 如: 0.3
    // key 是 string 如: '0.30'
    // 1. 参与排序需要 数字 将会更准确
    // 2. item.downPaymentRate 需要传递给 后台 为 '0.30' 这种字符串，这里做个转换
    _.forEach(value, (item) => {
      item.toSortDownPaymentRate = item.downPaymentRate
      item.downPaymentRate = key
    })
    return value
  })

  defaultPayment = _.uniqBy(
    _.orderBy(defaultPayment, 'toSortDownPaymentRate', 'asc'),
    'periods',
  )

  //默认选择最小首付比例
  //如果传入了指定的首付比例且指定首付比例存在于payments中，则使用传入的首付比例
  let minRate =
    downPaymentRate && payments[downPaymentRate]
      ? downPaymentRate
      : defaultPayment[0]?.downPaymentRate

  //后台是否只传了0首付，如果是则该用户隐藏首付比例，强制选择0首付
  const hideDPrateList = minRate === '0.0' && Object.keys(payments).length === 1

  //判断从登录页面传过来的分期数是否存在
  const periodList = defaultPayment.map((item) => String(item.periods))
  if (!periodList.includes(prevPeriod)) {
    prevPeriod = null
  }

  return {
    minRate,
    defaultPayment: fromJS(defaultPayment),
    prevPeriod,
    orderPrice,
    riskInstallmentResult,
    hideDPrateList,
  }
}

// 获取积分信息
async function getPointsInfo({ dispatch, orderId, price, totalPayAmount }) {
  const {
    data: { success, data: pointsInfo },
  } = await axios.post(
    '/capi/openpay/v2/order/installment/point',
    {
      orderNumber: orderId,
      orderFrontAmount: price,
      orderAfterAmount: totalPayAmount,
      businessType: 1, //openPay支付方式：1:线上支付 2：线下支付
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  success && dispatch(setPointsInfo(pointsInfo))
}
/*
 * 登录后重新获取订单信息
 * previousPeriod {string} 登录页面选择的分期数
 * fullPayOnly {boolean} 只能全款支付
 * callback {function} 请求完成后的回调
 * downPaymentRate {String} 用户选择的首付比例
 * couponId {number} 用户选择的优惠券id
 */
export const goGetOrderInfo = (
  previousPeriod,
  fullPayOnly,
  callback,
  downPaymentRate,
  couponId,
  dontRemovePromoCode,
  couponType,
) => {
  const state = store.getState()
  const appId = state.getIn(['main', 'appId']),
    refNo = state.getIn(['main', 'refNo']),
    sign = state.getIn(['main', 'sign'])
  const waitSocialSecurity = URL.getParam('securityStatus')
  const translate = Translate.getInstance().translate

  return async (dispatch) => {
    if (waitSocialSecurity) {
      dispatch(
        globalActionCreator.toggleLoading(
          true,
          translate('系统审核中，请耐心等待片刻'),
        ),
      )
    } else {
      dispatch(globalActionCreator.toggleLoading(true))
    }

    const installmentParam = {
      refNo,
      appId,
      sign,
      downPaymentRate,
      pageType: __isPc() ? 2 : 1, // pc: 2、mobile: 1
      checkoutTime: Date.now(),
    }

    // 如果选中coupon，增加couponType、periods参数
    if (couponId) {
      if (couponType) installmentParam.couponType = couponType
      installmentParam.couponId = couponId
      const coupons = state
        .getIn(['orderDetail', 'coupons', 'availableCouponList'])
        .toJS()
      const couponItem = coupons.find((item) => item.couponId === couponId)
      if (couponItem) {
        installmentParam.couponType = couponItem.couponType
      }
    }
    //region 默认选中优惠券逻辑
    // 第一次告诉后台默认选中一张
    // 后续根据couponId来得出是否要选中优惠券
    if (isFirstGetOrderInfo) {
      installmentParam.defaultSelectCoupon = true
      doneFirstGetOrderInfo()
    } else {
      installmentParam.defaultSelectCoupon = !!couponId
    }
    //endregion

    if (previousPeriod) installmentParam.periods = previousPeriod

    const {
      data: { success, errCode, errMsg, data },
    } = await axios.post(
      '/capi/openpay/order/get/installment',
      installmentParam,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    const uid = data?.uid
    const saLogger = Logger.getInstance()

    // 这里拿到uid之后再调一次神策登录，防止老系统跳新系统或者缓存登录的时候神策没有登录
    uid && saLogger.setUid(uid)
    uid && saLogger.login(uid)

    saLogger.onPageClick({
      ...loggerConfig.request_installment,
      isRequestSuccess: success ? 1 : 2,
      // 是否有零首付方案:1-是，0否
      isDownpayment: _.get(data, "payments['0.0']", false) ? 1 : 0,
      Aku_reason: errMsg,
      refno: refNo,
      orderId: data?.orderId,
    })

    if (waitSocialSecurity) {
      // 等待社保校验结果
      dispatch(__waitSocialSecurityResult())
    } else {
      dispatch(globalActionCreator.toggleLoading(false))
    }

    if (success) {
      //#region 预处理 payments 字段
      /**
       * input:  { '0.3':item }
       * output: { '0.30':item, '0.3':item }
       */
      _.forEach(data.payments, (item, key) => {
        data.payments[key] = item
        data.payments[(+key).toFixed(2)] = item
      })
      //#endregion
      const { reselect } = URL.allParam()
      const {
        isPaymentPending,
        isPaymentSuccessful,
        callbackPageUrl,
        installment,
        isChangeUser,
        noPasswordInfo,
        activityBanner, // banner图
        supportPaymentPassword, // 是否设置了支付密码
        payStatus,
        tokenLogin,
        buried,
        defaultSelectedDownPaymentRate,
        defaultSelectedPeriods,
        needCollectGps, // 是否需要上报地址信息
        activityInfo, // 活动信息
      } = data

      // 保存活动信息
      dispatch(
        __addNewStateFromOrder({
          activityInfo,
        }),
      )

      // 获取积分信息
      getPointsInfo({
        dispatch,
        orderId: data.orderId,
        price: data.price,
        totalPayAmount: data.totalPayAmount,
      })
      // 保存用户已经设置过的还款日
      dispatch(setRepaymentDay(data.userRepaymentDay))
      dispatch(setExchangeRateVO(data.exchangeRateVO))
      dispatch(setMerchantName(data.merchantName))
      dispatch(setMerchantCouponVOS(data.merchantCouponVOS))

      // 如果是token订单，设置标记
      if (tokenLogin) storage.setSession('tokenLogin', tokenLogin)
      // 临时保存一些埋点透传数据
      if (buried) {
        storage.setSession('Aku_orderStatus', buried.orderStatus)
        storage.setSession('gateway', buried.paymentGateway)
      }
      // 已经支付成功或者失败的订单，直接跳转结果页
      if (
        payStatus === PaymentResultStatusNumber.success ||
        payStatus === PaymentResultStatusNumber.fail
      ) {
        const status = statusNumberToDisplayStatus(payStatus)
        dispatch(
          push({
            pathname: `./paymentResult`,
            state: { callbackPageUrl },
            search: `${window.location.search}&status=${status}`,
          }),
        )
        return
      }

      // 保存 用户是否设置了支付密码
      dispatch({
        type: actions.SET_SUPPORT_PAYMENT_PWD,
        supportPaymentPassword,
      })

      // 处理banner图
      dispatch(__setOrderDetailBanner(activityBanner || {}))

      // 获取小额免密的信息
      const { meetNoPassword, firstMeetNoPassword } = noPasswordInfo || {}

      //从订单继续页回到确认下单页，不会走下方逻辑
      //因为我是想重新选择支付方案呀
      //reselect表示我当前是想重新选择支付方案
      //isChangeUser表示当前用户不再是之前下单的用户了
      if (!reselect && !isChangeUser) {
        //如果该订单是pending状态，则跳转到继续支付页
        //在继续支付页可以继续支付也可以重新选择支付方案
        if (isPaymentPending) {
          const { payInfo } = data
          dispatch(__jumpToPayContinue(payInfo, installment))
          return
        } else if (isPaymentSuccessful) {
          dispatch(__jumpToPaySuccess(installment, callbackPageUrl))
          return
        }
      }

      //  meetNoPassword 为true 的时候，展示小额免密控件 如果需要展示控件 就默认选中 否则为false
      dispatch(goSetShowSmallPayController(!!meetNoPassword))
      dispatch(goSetIsSmallPay(!!meetNoPassword))
      // 设置是否显示小额免密说明弹窗 第一次
      dispatch(goToggleQuickPaymentInstructionVisible(firstMeetNoPassword))

      // 新接口是没有plans的，这里为了保持一致，新建了一个plans放payments
      if (!data.plans) {
        data.plans = {}
        data.plans.payments = data.payments
        data.plans.riskInstallmentResult = data.notBillAbleReason
        data.plans.price = data.price
        data.plans.totalPayAmount = data.totalPayAmount

        if (
          data.plans.riskInstallmentResult === limitCapacityMap.LIMIT_OUT_CREDIT
        ) {
          dispatch(limitOutCreditAction())
          return
        }
        if (
          data.plans.riskInstallmentResult ===
          limitCapacityMap.LIMIT_REJECT_BY_RISK
        ) {
          const text = translate(
            '基于COVID-19疫情期间您的最新信用评估，很抱歉您不能通过0首付分期支付本订单，请使用其他首付比例或者全款支付。当此订单能通过0首付分期支付时，我们会及时通知您。请注意安全和身体健康！',
          )
          dispatch(
            globalActionCreator.toggleAlertDialog(true, {
              title: translate('提示'),
              content: text,
              closable: false,
              footer: [
                {
                  text: translate('我知道了'),
                  onPress: () => {
                    dispatch(globalActionCreator.globalLogOut())
                  },
                },
              ],
            }),
          )
          return
        } else if (
          // 新流程中，没有全款
          data.plans.riskInstallmentResult !== limitCapacityMap.LIMIT_SUFFICIENT
        ) {
          dispatch(
            replace({
              pathname: `./applylimit-instruction`,
              search: window.location.search,
            }),
          )
          return
        }
      }

      //如果是重新选择，则清空优惠码
      !dontRemovePromoCode && reselect && dispatch(goSetCouponCode(''))
      //把订单信息存到state里面
      dispatch(__addNewStateFromOrder(data))
      //定价之后更新curCouponId
      if (data?.coupons) {
        dispatch(dispatch(setCurrentCouponId(data.coupons.couponId)))
      }

      const {
        minRate,
        defaultPayment,
        orderPrice,
        riskInstallmentResult,
        hideDPrateList,
      } = computeCrucialDataFromOrder(
        data.plans,
        defaultSelectedPeriods,
        fullPayOnly,
        tranformDownpaymentRate(defaultSelectedDownPaymentRate),
      )

      //隐藏首付比例
      hideDPrateList
        ? dispatch(__toggleDownpaymentRateDisplay(false))
        : dispatch(__toggleDownpaymentRateDisplay(true))

      // 若计算出来为首付比例为1.0，则显示全款选项
      minRate === '1.0' && dispatch(__toggleFullpaymentDisplay(true))

      //设置首付比例
      dispatch(
        goSetDownpaymentRate(
          minRate,
          defaultPayment,
          defaultSelectedPeriods,
          orderPrice,
        ),
      )

      //处理埋点
      handleDataReportAfterGetOrderInfo(riskInstallmentResult, defaultPayment)

      /**
       * IP验证是安全校验环节的组件之一，但是需要跳转到第三方页面去完成验证，这里交互比较割裂
       * 如果是从ip验证页面回到订单确认页，需要恢复页面数据, 并且自动下单
       */
      if (storage.get(IP_VERIFY_RESULT_KEY) === '1') {
        // 恢复跳转去IP验证之前缓存的页面数据
        const data = storage.get(IP_VERIFY_PAGE_CACHE_KEY)
        dispatch(__addNewStateFromOrder(data))
        // 重置下单按钮loading状态
        dispatch(__disableOrderButton(false))
        // 设置IP组件的operationId
        dispatch(__saveOperationId(localStorage.getItem(SEV_OPERATION_ID_KEY)))
        // IP验证跳回来订单页不需要再显示安全组件
        dispatch(toggleSecurityVerification(false))
        // IP组件验证通过
        if (localStorage.getItem(SEV_IP_VALIDATE_STATUS_KEY) === '1') {
          // 调起下单流程
          dispatch(doAddOrderLogic())
        }
        // 及时清理IP验证标志
        storage.set(IP_VERIFY_RESULT_KEY, '0')
      }

      // 调用sdk上传地址
      if (needCollectGps && !isLocationUploaded) {
        dispatch(
          globalActionCreator.globalReportLocation({
            scenario_number: SCENARIO_NUMBER_MAP.ADD_ORDER,
          }),
        )
        doneLocationUploaded()
      }
    } else {
      const translate = Translate.getInstance().translate

      switch (errCode) {
        case 'openpay.0101':
          dispatch(
            globalActionCreator.toggleToast(
              true,
              translate('您输入的优惠码不存在，请检查后重新输入。'),
            ),
          )
          break

        case 'openpay.0102':
          // // 走到这个分支证明是选择首付比例时调用的check.do
          // // 当优惠码不匹配当前选择的首付比例时
          // // 需要重新调用check.do，并且不把优惠码传给后台，真麻烦

          // dispatch(goGetOrderInfo(null, null, null, downPaymentRate, null))
          dispatch(
            globalActionCreator.toggleToast(
              true,
              translate('您的订单不符合优惠码使用规则。'),
            ),
          )
          break
        case '10810110050036':
          dispatch(
            globalActionCreator.toggleToast(
              true,
              translate('该订单暂时无法支付，请重新下单。'),
            ),
          )
          break
        case '10810110050054':
          dispatch(
            globalActionCreator.toggleAlertDialog(true, {
              title: translate('温馨提示'),
              content: translate(
                'Akulaku Elite Card处于激活中状态时无法使用分期支付，如需使用请去Akulaku App取消激活Akulaku Elite Card或稍后再试，如有疑问请联系客服',
              ),
              footer: translate('我知道了'),
            }),
          )
          break

        default:
          dispatch(
            globalActionCreator.toggleToast(
              true,
              errMsg || translate('网络异常，请稍后重试'),
            ),
          )
          break
      }
    }

    typeof callback === 'function' && callback(data, success)
  }
}

/*
 * 等待社保结果
 */
const __waitSocialSecurityResult = () => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(globalActionCreator.toggleLoading(false))
    }, 5000)
  }
}

/*
 * 更新分期计划
 * couponCode {String} 优惠码
 * callback {Function} 更新成功后的回调
 */
export const goUpdateOrderPlanInfo = (couponCode, callback) => {
  const state = store.getState()
  const appId = state.getIn(['main', 'appId']),
    refNo = state.getIn(['main', 'refNo']),
    sign = state.getIn(['main', 'sign']),
    downPaymentRate = state.getIn(['orderDetail', 'downpaymentRate'])

  return async (dispatch) => {
    dispatch(globalActionCreator.toggleLoading(true))
    const {
      data: { success, errCode, errMsg, data },
    } = await axios.get('/installment/api/json/openpay/user/balance/check.do', {
      params: {
        refNo,
        appId,
        sign,
        promotionCode: couponCode,
        downPaymentRate,
      },
      header: { vc: 1 },
    })
    dispatch(globalActionCreator.toggleLoading(false))

    if (success) {
      const { defaultPayment, prevPeriod, orderPrice } =
        computeCrucialDataFromOrder(data.plans, null, null, downPaymentRate)

      //设置首付比例为当前选择的首付比例，之前是选择最小比例
      dispatch(
        goSetDownpaymentRate(
          downPaymentRate,
          defaultPayment,
          prevPeriod,
          orderPrice,
        ),
      )
      //如果有优惠码，则收起优惠码输入弹窗
      couponCode && dispatch(goToggleCouponCodeForm(false))

      //更新分期计划
      dispatch(__updateNewPlanToState(data.plans))
    } else {
      const translate = Translate.getInstance().translate

      switch (errCode) {
        case 'openpay.0101':
          dispatch(
            goSetCouponErrorText(
              translate('您输入的优惠码不存在，请检查后重新输入。'),
            ),
          )
          break
        case 'openpay.0102':
          dispatch(
            goSetCouponErrorText(translate('您的订单不符合优惠码使用规则。')),
          )
          break
        case 'openpay.0103':
          dispatch(
            goSetCouponErrorText(translate('您的订单不符合优惠码使用规则。')),
          )
          break
        case 'openpay.0012':
          dispatch(
            goSetCouponErrorText(translate('您的订单已取消，无法添加优惠码')),
          )
          break
        case 'openpay.0005':
          dispatch(
            goSetCouponErrorText(translate('订单处理中，无法添加优惠码')),
          )
          break
        case 'openpay.0080':
          dispatch(
            goSetCouponErrorText(translate('订单已支付，无法添加优惠码')),
          )
          break
        default:
          dispatch(globalActionCreator.toggleToast(true, errMsg))
          break
      }
    }

    callback && callback(success)
  }
}

/*
 * 下单：新流程
 */
const __placeOrderNew = async () => {
  try {
    const state = store.getState()
    const deviceInfoReportData = JSON.stringify(
      bridge.isAkulaku
        ? state.get('main').toJS().deviceData
        : translateDeviceData(state.get('main').toJS().deviceData),
    )
    const fingerprint = state.getIn(['main', 'fingerprint']),
      os = state.getIn(['main', 'os']),
      deviceId = storage.get('deviceId') || 'unknown',
      userAgent = state.getIn(['main', 'userAgent']),
      appId = state.getIn(['main', 'appId']),
      sign = state.getIn(['main', 'sign']),
      refNo = state.getIn(['main', 'refNo'])
    const curPeriod = state.getIn(['orderDetail', 'curPeriod'])
    const downPayment = state.getIn(['orderDetail', 'downPayment'])
    const uid = state.getIn(['orderDetail', 'uid'])
    const curMonthlyPay = state.getIn(['orderDetail', 'curMonthlyPay'])
    const downpaymentRate = state.getIn(['orderDetail', 'downpaymentRate'])

    const curPaymentStrategy = state.getIn([
      'orderDetail',
      'curPaymentStrategy',
    ])
    const curPaymentStrategyWithPeriod = curPaymentStrategy.filter((item) => {
      return item.get('periods') === +curPeriod
    })
    const monthlyInterest = curPaymentStrategyWithPeriod
      .get('0')
      .get('monthlyInterest')
    const installmentAmount = curPaymentStrategyWithPeriod
      .get('0')
      .get('installmentAmount')
    const principal = curPaymentStrategyWithPeriod.get('0').get('principal')
    const couponId = state.getIn(['orderDetail', 'curCouponId'])
    const loginMethodToken = URL.getParam('loginMethodToken')
    const paymentPasswordOperationId = state.getIn([
      'orderDetail',
      'paymentPasswordOperationId',
    ])

    // 小额免密信息
    const showSmallPayController = state.getIn([
      'orderDetail',
      'showSmallPayController',
    ])
    const isSmallPay = state.getIn(['orderDetail', 'isSmallPay'])
    let requestId = state.getIn(['orderDetail', 'paymentId'])
    const latitude =
      state.getIn(['main', 'deviceData', 'location', 'latitude']) || 0
    const longitude =
      state.getIn(['main', 'deviceData', 'location', 'longitude']) || 0
    const postData = {
      uid,
      currencyId: 1,
      appId,
      sign,
      refNo,
      periods: curPeriod,
      password: storage.getSession('privateKey') || '',
      fingerprint,
      installmentAmount,
      principal,
      downPayment,
      monthlyPayment: curMonthlyPay,
      monthlyRate: monthlyInterest,
      downPaymentRate: downpaymentRate,
      deviceInfoReportData,
      userAgent,
      browserName: 'chrome',
      browserVersion: '123',
      os,
      deviceId,
      deviceModel: 3434,
      latitude,
      longitude,
      couponId, // 下单前需要优惠券id
      loginMethodToken,
      meetNoPasswordOrder: showSmallPayController && isSmallPay ? true : false,
      requestId, // 支付id
      pageType: __isPc() ? 2 : 1, // pc: 2、mobile: 1
      clickTime: state.getIn(['orderDetail', 'clickPayTime']), // 点击下单按钮的时间点
      deviceType: getDeviceType(),
      acquiringType: URL.getParam('acquiringType'),
    }
    // 如果有支付密码校验，吧支付密码校验id带上
    if (paymentPasswordOperationId) {
      postData.paymentPasswordOperationId = paymentPasswordOperationId
    }
    // 如果走了风控 把operationId带上
    const needSecurityVerification = state.getIn([
      'orderDetail',
      'needSecurityVerification',
    ])
    const operationId = state.getIn(['orderDetail', 'operationId'])
    const riskCheckPass = state.getIn(['orderDetail', 'riskCheckPass'])
    if (needSecurityVerification && curPeriod !== '0' && riskCheckPass) {
      postData.operationId = operationId
    }
    // IP组件传operationId参数
    // IP组件验证通过
    if (localStorage.getItem(SEV_IP_VALIDATE_STATUS_KEY) === '1') {
      // 调起下单流程
      postData.operationId = operationId
    }
    // 如果有选中优惠券，增加couponType参数
    if (couponId) {
      const coupons = state
        .getIn(['orderDetail', 'coupons', 'availableCouponList'])
        .toJS()
      const couponItem = coupons.find((item) => item.couponId === couponId)
      postData.couponType = couponItem.couponType
    }

    // 如果没有couponType，couponId也不应该传
    if (!postData.couponType) delete postData.couponId

    const repaymentDay = state.getIn(['orderDetail', 'repaymentDay'])
    const userSetRepaymentDate = state.getIn([
      'orderDetail',
      'userSetRepaymentDate',
    ])
    // 用户是否设置了还款日，true为已经设置，false为未设置
    // 未设置的情况下需要传给后台还款日
    if (!userSetRepaymentDate) {
      postData.repaymentDay = repaymentDay
    }
    if (bridge.isAkulaku) {
      postData.scenesCode = SECURITY_VERIFICATION_BIZ_TYPE.APP_ADD_ORDER
    }
    const smDeviceData = await getSmDeviceData()
    return axios.post(
      '/capi/openpay/order/add',
      {
        smDeviceData,
        ...postData,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  } catch (err) {
    console.log(err)
    return {}
  }
}

/*
 * 设置风控验证码错误信息
 * errText {string} 错误信息
 */
export const goSetCodeErrorText = (errText) => ({
  type: actions.SET_CODE_ERROR_TEXT,
  errText,
})

/*
 * 设置登录密码错误信息
 * errText {string} 错误信息
 */
export const goSetLoginPwdErrorText = (errText) => ({
  type: actions.SET_LOGIN_PWD_ERROR_TEXT,
  errText,
})

/*
 * 显示登录密码输入弹窗
 */
export const toggleLoginPasswordDialog = (show) => {
  return {
    type: actions.SHOW_LOGIN_PASSWORD_DIALOG,
    show,
  }
}

/*
 * 保存风控operationId
 */
const __saveOperationId = (operationId) => ({
  type: actions.SAVE_OPERATION_ID,
  operationId,
})

//保存用户登录密码到session，下单接口需要传
const __savePrivateKey = (key) => {
  return () => {
    storage.setSession('privateKey', key)
  }
}

//禁用下单按钮，下单接口较慢
export const __disableOrderButton = (disabled) => ({
  type: actions.DISABLE_ORDER_BUTTON,
  disabled,
})

export const toggleRiskStatus = (riskCheckPass) => ({
  type: actions.TOGGLE_RISK_CHECK_STATUS,
  riskCheckPass,
})

/*
 * 执行下单逻辑
 * 包括调用下单接口以及决定下单后往哪个页面跳转
 * orderCaptcha {String} 下单时的短信验证码（自动登录情况下，用户可选择密码下单和手机验证码下单）
 */
export const doAddOrderLogic = (orderCaptcha) => {
  return async (dispatch) => dispatch(__addOrderLogicNew())
}

const __addOrderLogicNew = (orderCaptcha) => {
  const translate = Translate.getInstance().translate
  const state = store.getState()
  const curPeriod = state.getIn(['orderDetail', 'curPeriod'])
  const callbackPageUrl = encodeURIComponent(
    state.getIn(['orderDetail', 'callbackPageUrl']),
  )

  return async (dispatch) => {
    dispatch(__disableOrderButton(true))
    const { data = {} } = await __placeOrderNew()
    dispatch(__disableOrderButton(false))

    const saLogger = Logger.getInstance()
    saLogger.onPageClick({
      ...loggerConfig.confirm_payment,
      code: data.errCode,
      Aku_msg: data.errMsg,
    })
    if (data.success) {
      const { bizOrderId, amount, payId, payStatus } = data.data
      // 下单接口成功之后 重置operationId
      dispatch(__saveOperationId())
      if (payStatus !== PaymentResultStatusNumber.payingForDownPayment) {
        const status = statusNumberToDisplayStatus(payStatus)
        dispatch(
          push({
            pathname: `./paymentResult`,
            state: { curPeriod, price: amount, callbackPageUrl },
            search: `${window.location.search}&status=${status}`,
          }),
        )
      } else {
        dispatch(
          push({
            pathname: `./paymentMethod`,
            state: {
              bizIds: [bizOrderId],
              payId,
              price: amount,
              curPeriod,
              callbackPageUrl,
            },
            search: window.location.search,
          }),
        )
      }
    } else if (
      [
        '10810110050011', //
        '10810110050031', // 优惠券不可用
        '10810110050036', // 暂时不可下单，请重新下单
        '10810110050037', // 需要输入密码
        '10810110061000', // 渠道管控措施拦截
      ].includes(data.errCode)
    ) {
      dispatch(generatePaymentID())
      // 以上错误码直接在下单页提示
      const { errCode, errMsg } = data
      // saLogger.onPageError(elementId.ERROR_PAYMENT_FAIL, errMsg)
      switch (errCode) {
        case '10810110050011':
          dispatch(globalActionCreator.toggleToast(true, errMsg))
          dispatch(setCurrentCouponId(''))
          dispatch(goGetOrderInfo())
          break
        case '10810110050031':
          dispatch(
            globalActionCreator.toggleAlertDialog(true, {
              title: translate('提示'),
              content: translate('优惠券暂不可用，请换一张优惠券'),
              footer: translate('我知道了'),
            }),
          )
          dispatch(setCurrentCouponId(''))
          dispatch(goGetOrderInfo())
          break
        case '10810110050036':
          dispatch(
            globalActionCreator.toggleAlertDialog(true, {
              title: translate('提示'),
              content: translate('该订单暂时无法支付，请重新下单。'),
              footer: translate('我知道了'),
            }),
          )
          break
        // 需要输入密码
        case '10810110050037':
          dispatch(toggleLoginPasswordDialog(true))
          break
        // 渠道管控措施拦截
        case '10810110061000':
          dispatch(
            globalActionCreator.toggleAlertDialog(true, {
              title: translate('提示'),
              content: errMsg,
              footer: [
                {
                  text: translate('返回商户'),
                  onPress: () => {
                    location.href = decodeURIComponent(
                      state.getIn([
                        'orderDetail',
                        'commonInfo',
                        'callbackPageUrl',
                      ]),
                    )
                  },
                },
              ],
            }),
          )
          break
      }
    } else {
      dispatch(generatePaymentID())
      // 默认的错误跳转到结果页
      const { errCode, errMsg } = data
      const price = state.getIn(['orderDetail', 'plans', 'price'])
      dispatch(
        push({
          pathname: `./paymentResult`,
          state: { curPeriod, price, errCode, errMsg },
          search: `${window.location.search}&status=${PaymentResultStatus.fail}`,
        }),
      )
    }
  }
}

/**
 * 安全校验流程
 * @param {string} operationId 支付密码校验id
 */
export const continueRiskCheck = ({ operationId }) => {
  return async (dispatch, getState) => {
    const state = getState()
    const needRiskChcek = state.getIn(['orderDetail', 'needRiskChcek']),
      oldJumpHasEnterPassword = state.getIn([
        'orderDetail',
        'oldJumpHasEnterPassword',
      ]),
      skipPassword = state.getIn(['orderDetail', 'skipPassword']),
      isAutoLogin = state.getIn(['orderDetail', 'isAutoLogin'])

    // 如果是从支付密码校验回调流程进来的，要保存操作id
    if (operationId !== undefined) {
      // 保存支付密码校验的operationId
      dispatch(setPaymentPasswordOperationId(operationId))
    }

    // 风控改为安全组件 不需要这边控制了
    if (needRiskChcek) {
      // 调起安全组件之前先缓存页面状态数据（IP组件回来需要恢复并自动下单）
      storage.set(IP_VERIFY_PAGE_CACHE_KEY, state.getIn(['orderDetail']).toJS())
      storage.set(IP_VERIFY_SERACH_KEY, location.search)

      dispatch(toggleSecurityVerification(true))
      dispatch(__disableOrderButton(false))
      // 上报设备信息
      dispatch(
        globalActionCreator.globalSendDeviceData({
          scenario_number: SCENARIO_NUMBER_MAP.SECURITY_CHECK,
        }),
      )
      return
    }

    /**
     * 20201202 只要是跳过密码的 就不需要再出现密码输入框了 直接执行下单逻辑
     * **/

    dispatch(
      newOperatorBeforePlaceOrder({
        oldJumpHasEnterPassword,
        skipPassword,
        isAutoLogin,
      }),
    )
  }
}

/*
 * 下单前操作（非自动登录时下单需要调用改方法）
 * 下单前一般要调用风控 现在由安全组件处理
 * curPayment {number} 当前所付金额
 * needRiskChcek {boolean} 是否需要风控验证
 * isAutoLogin {Boolean} 是否是自动登录，如果skipPassword 为true， 那就不需要判断这个参数了
 * skipPassword {Boolean} 是否是需要跳过密码验证 现在是只有小额免密的跳过密码逻辑
 * oldJumpHasEnterPassword {Boolean} 输入密码之后 还是会走到这里来 所以需要判断是否已经校验过密码了 这个是针对uid切量过程中老系统过来的用户，无法获取到密码加入的 默认为false 输入密码之后改为true
 * 这里其实是应该在和优化下 现在 isAutoLogin,skipPassword oldJumpHasEnterPassword 其实都是在做透传而已
 */
export const goOpreateBeforePlaceOrder = ({
  needRiskChcek,
  isAutoLogin,
  skipPassword,
  oldJumpHasEnterPassword = false,
}) => {
  return async (dispatch, getState) => {
    const state = getState()
    const supportPaymentPassword = state.getIn([
      'orderDetail',
      'supportPaymentPassword',
    ])
    const paymentPasswordCheckPass = state.getIn([
      'orderDetail',
      'paymentPasswordCheckPass',
    ])
    dispatch(__disableOrderButton(true))
    dispatch(goSaveClickPayTime(Date.now()))

    // 缓存参数
    dispatch(
      setPasswordVerificationData({
        needRiskChcek,
        oldJumpHasEnterPassword,
        skipPassword,
        isAutoLogin,
      }),
    )

    // 在安全校验之前先判断是否要校验支付密码, 如果要校验支付密码在支付密码校验之后再继续安全校验流程
    if (supportPaymentPassword && !paymentPasswordCheckPass) {
      return dispatch(togglePaymentPasswordVerification(true))
    }
    // 安全校验流程
    dispatch(continueRiskCheck({}))
  }
}

/*
 * 下单前登录（自动登录时下单需要调用该方法）
 */
export const goLoginBeforePlaceOrder = (password, needRiskChcek) => {
  const translate = Translate.getInstance().translate
  const saLogger = Logger.getInstance()

  return async (dispatch, getState) => {
    const state = getState()
    const riskCheckPass = state.getIn(['orderDetail', 'riskCheckPass']) //风控是否以及校验过了
    const showSmallPayController = state.getIn([
      'orderDetail',
      'showSmallPayController',
    ])

    dispatch(globalActionCreator.toggleLoading(true))
    const {
      data: { success, errCode, errMsg },
    } = await axios.post(
      '/capi/openpay/order/check/pay/password',
      { passWord: md5(password) },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    dispatch(globalActionCreator.toggleLoading(false))
    // 设置密码弹窗里面的小额免密弹窗 只有满足小额免密的情况下才需要执行这个
    showSmallPayController &&
      dispatch(goSetShowQuickPayTipInPasswordModal(!success))

    if (success) {
      dispatch(__savePrivateKey(md5(password)))
      dispatch(
        goOpreateBeforePlaceOrder({
          needRiskChcek: riskCheckPass ? false : needRiskChcek,
          oldJumpHasEnterPassword: true,
        }),
      )
      dispatch(toggleLoginPasswordDialog(false))
      // 支付密码校验通过
      saLogger.onPageClick(loggerConfig.password_passed)
    } else {
      saLogger.click({
        ...loggerConfig.confirm_payment,
        code: errCode,
        Aku_msg: errMsg,
      })
      dispatch(globalActionCreator.toggleLoading(false))
      switch (errCode) {
        case 'PROFILE.0002':
        case 'PROFILE.0025':
          dispatch(
            goSetLoginPwdErrorText(translate('您输入的密码有误，请重新输入')),
          )
          break

        default:
          dispatch(globalActionCreator.toggleToast(true, errMsg))
          break
      }
    }
  }
}

/*
 * 保存订单信息到localstorage
 * 为了兼容老版本菲律宾授信
 */
export const goSaveOrderParam = (curPeriod, countryCode) => {
  const state = store.getState()
  const appId = state.getIn(['main', 'appId'])
  const refNo = state.getIn(['main', 'refNo'])
  const sign = state.getIn(['main', 'sign'])
  const { phone } = URL.allParam()

  return (dispatch) => {
    storage.set('orderParam', { appId, refNo, sign, period: curPeriod })
    // phone 不存在的情况下 就不设置了
    phone && storage.set('phoneNumber', phone)
    // if (countryCode === 'PH') {
    //   storage.set('orderParam', { appId, refNo, sign, period: curPeriod })
    //   storage.set('phoneNumber', phone)
    // } else {
    //   storage.remove('orderParam')
    //   storage.remove('phoneNumber')
    // }
  }
}

/*
 * 选择全款（一般使用户下单被风控拒绝，只能使用全款支付）
 */
export const goChooseFullpayOnly = () => {
  const state = store.getState()
  const fullPayment = state.getIn(['orderDetail', 'plans', 'payments', '1.0'])

  return async (dispatch) => {
    //如果取不到全款，那就是后台没有返回，流程直接卡住，产品说的
    if (!fullPayment) {
      return false
    }
    const monthlyPay = fullPayment.get(0).get('monthlyPay')
    const downpayment = fullPayment.get(0).get('downPayment')
    const curPayment = downpayment
    const lastMonthlyPay = fullPayment.get(0).get('lastMonthlyPay')
    // 先显示全款
    await dispatch(__toggleFullpaymentDisplay(true))
    // 设置当前首付比例为1.0
    dispatch(setDownpaymentRate('1.0', fullPayment))
    dispatch(goSetFullPayOnly(true))
    dispatch(
      goChoosePeriod('0', monthlyPay, downpayment, curPayment, lastMonthlyPay),
    )
  }
}

// 设置优惠券弹窗显示内容
export const goSetCouponModalFlag = (couponModalFlag) => ({
  type: actions.SET_COUPON_MODAL_FLAG,
  couponModalFlag,
})

// 设置弹窗里面的
export const goSetPrevCouponId = (prevCouponId) => ({
  type: actions.SET_PREV_COUPON_ID,
  prevCouponId,
})

// 显示/隐藏支付密码校验组件
export const togglePaymentPasswordVerification = (visible) => ({
  type: actions.SET_PAYMENT_VERIFICATION_VISIBLE,
  showPayVerificationModal: visible,
})

// 设置支付密码校验ID
export const setPaymentPasswordOperationId = (id) => ({
  type: actions.SET_PAYMENT_VERIFICATION_ID,
  paymentPasswordOperationId: id,
})

// 显示/隐藏安全组件
export const toggleSecurityVerification = (securityVerificationVisible) => {
  return async (dispatch) => {
    dispatch(globalActionCreator.toggleLoading(securityVerificationVisible))
    dispatch({
      type: actions.TOGGLE_SECURITY_VERIFICATION,
      securityVerificationVisible,
    })
  }
}

// 是否需要安全组件校验
export const goGetPublicConfig = (platform) => {
  return async (dispatch) => {
    // 修改 opai 为 capi
    const {
      data: { data, success, errMsg },
    } = await axios.get('/capi/openpay/public/config/common')
    if (success) {
      const { showSecurityVerification, env } = data || {}
      // 移动端预发环境和测试环境开启调试面板
      if (platform === 'mobile' && env && (env === 'pre' || env === 'test')) {
        Eruda()
      }
      dispatch(__setNeedSecurityVerification__(showSecurityVerification))
      dispatch(__setCommonInfo__(data))
    } else {
      dispatch(globalActionCreator.toggleToast(true, errMsg))
    }
  }
}

// 设置是否需要展示安全组件
const __setNeedSecurityVerification__ = (needSecurityVerification) => ({
  type: actions.SET_SHOW_SECURITY_VERIFICATION,
  needSecurityVerification,
})

// 设置common信息
const __setCommonInfo__ = (data) => ({
  type: actions.SET_COMMON_INFO,
  data,
})

// 安全组件校验成功
export const afterSecurityVerification = ({
  operationId,
  isAutoLogin,
  skipPassword,
}) => {
  return async (dispatch) => {
    dispatch(toggleRiskStatus(true))
    dispatch(__saveOperationId(operationId))
    dispatch(toggleSecurityVerification(false))
    /**
     * 20201202 只要是跳过密码的 就不需要再出现密码输入框了 直接执行下单逻辑
     * **/

    dispatch(
      newOperatorBeforePlaceOrder({
        skipPassword,
        isAutoLogin,
      }),
    )
    /**
     * uid 切量中的临时处理方案 因为从老系统跳转到新系统无法获取到密码
     * 这里只需要判断是否老系统调过来的 因为走到这里的 肯定是没有输入过密码的
     * 上面那个只需要有个checkFromOld 参数，就是为了判断是否输入过密码 正常这个参数应该叫 hasInputPassword 之前取名不严谨
     * **/

    // if (jumpFormOld) {
    //   dispatch(toggleLoginPasswordDialog(true))
    // } else {
    //   //只有快捷登录才会走快捷支付逻辑
    //   if (isAutoLogin) {
    //     //小于150K直接下单
    //     if (skipPassword) {
    //       await dispatch(doAddOrderLogic())
    //     } else {
    //       dispatch(toggleLoginPasswordDialog(true))
    //     }
    //   } else {
    //     await dispatch(doAddOrderLogic())
    //     return
    //   }
    // }
  }
}

/**
 * 设置是否需要展示小额免密空间
 * */

export const goSetShowSmallPayController = (bool) => ({
  type: actions.SET_SHOW_SMALL_PAY_CONTROLLER,
  showSmallPayController: bool,
})

/**
 * 设置小额免密的值
 * */

export const goSetIsSmallPay = (isSmallPay) => ({
  type: actions.SET_IS_SMALL_PAY,
  isSmallPay,
})

/**
 * 20201202 下单前置逻辑调整，去掉
 * needRiskChcek {boolean} 是否需要风控验证
 * isAutoLogin {Boolean} 是否是自动登录，如果skipPassword 为true， 那就不需要判断这个参数了
 * skipPassword {Boolean} 是否是需要跳过密码验证 现在是只有小额免密的跳过密码逻辑
 * oldJumpHasEnterPassword {Boolean} 输入密码之后 还是会走到这里来 所以需要判断是否已经校验过密码了 这个是针对uid切量过程中老系统过来的用户，无法获取到密码加入的 默认为false 输入密码之后改为true
 * **/

const newOperatorBeforePlaceOrder = ({
  oldJumpHasEnterPassword = false,
  skipPassword,
  isAutoLogin,
}) => {
  return async (dispatch, getState) => {
    const state = getState()
    const jumpFormOld = state.getIn(['main', 'jumpFromOld'])
    const isPwdLogin = URL.getParam('isPwdLogin')
    const paymentPasswordCheckPass = state.getIn([
      'orderDetail',
      'paymentPasswordCheckPass',
    ])
    //#region 拉起验证密码登录弹框逻辑
    // 是否拉起密码登录页，true表示拉起 后台控制字段
    const isPopupLoginPage = state.getIn(['orderDetail', 'isPopupLoginPage'])
    const shouldPopupLoginPage =
      // 老系统登录并且不是密码登录
      (jumpFormOld && isPwdLogin !== 'true' && isPopupLoginPage) ||
      // 如果是缓存登录的 就需要输入密码
      (isAutoLogin && isPopupLoginPage)
    //#endregion

    // 小额免密或者已经校验过支付密码
    // 都可以直接下单而不用弹出密码输入框
    // 老系统是密码登录 或 已输入过密码则直接下单
    if (skipPassword || paymentPasswordCheckPass || oldJumpHasEnterPassword) {
      await dispatch(doAddOrderLogic())
    } else if (shouldPopupLoginPage) {
      dispatch(toggleLoginPasswordDialog(true))
    } else {
      await dispatch(doAddOrderLogic())
    }
  }
}

/**
 * 20210105 兑换优惠码
 * yapi：http://testyapi.akulaku.com/project/1212/interface/api/116114
 * **/

export const applyCouponCode = (couponCode) => {
  return async (dispatch) => {
    const translate = Translate.getInstance().translate
    const state = store.getState()
    const appId = state.getIn(['main', 'appId'])
    const refNo = state.getIn(['main', 'refNo'])
    const curPeriod = state.getIn(['orderDetail', 'curPeriod'])
    const downpaymentRate = state.getIn(['orderDetail', 'downpaymentRate'])
    const {
      data: { data, success, errMsg },
    } = await axios.post(
      '/capi/openpay/order/coupon/code/exchange',
      {
        code: couponCode,
        merchantId: appId,
        refNo,
      },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    const saLogger = Logger.getInstance()
    if (success) {
      dispatch(
        globalActionCreator.toggleToast(true, translate('优惠码使用成功')),
      )
      dispatch(setCurrentCouponId(data.couponId))
      dispatch(goToggleBasicPopup(false))
      dispatch(
        goGetOrderInfo(
          curPeriod,
          null,
          null,
          downpaymentRate,
          data.couponId,
          null,
          data.couponType,
        ),
      )

      // 兑换成功埋点
      saLogger.onPageClick(loggerConfig.code_exchange_success)

      BizTracker.click({
        cn: 12,
        sn: 390004,
        ext: {
          // coupon_type：优惠券类型（1-满减券，2-满折券，3-利息券）
          // coupon_amount：优惠券金额
          coupon_type: data.couponType,
          coupon_amount: data.discount,
        },
      })
    } else {
      saLogger.onPageClick(loggerConfig.code_exchange_fail)
      dispatch(globalActionCreator.toggleToast(true, errMsg))
    }
  }
}

/**
 * 设置小额免密的弹窗 第一次进入显示的弹窗
 * */
export const goToggleQuickPaymentInstructionVisible = (
  quickPaymentInstructionVisible,
) => ({
  type: actions.TOGGLE_QUICK_PAYMENT_INSTRUCTION_VISIBLE,
  quickPaymentInstructionVisible,
})

/**
 * 设置小额免密的弹窗 下单时的提示弹窗
 * */

export const goToggleQuickPaymentReminderVisible = (
  quickPaymentReminderVisible,
) => ({
  type: actions.TOGGLE_QUICK_PAYMENT_REMINDER_VISIBLE,
  quickPaymentReminderVisible,
})

/**
 * 设置小额免密的弹窗 下单时的提示弹窗 是否提示过
 * */

export const goToggleQuickPaymentReminderHasOpen = (
  quickPaymentReminderHasOpen,
) => ({
  type: actions.TOGGLE_QUICK_PAYMENT_REMINDER_HAS_OPEN,
  quickPaymentReminderHasOpen,
})

/**
 * 设置密码弹窗小额免密提示
 * */

export const goSetShowQuickPayTipInPasswordModal = (
  showQuickPayTipInPasswordModal,
) => ({
  type: actions.SET_SHOW_QUICK_PAY_TIP_IN_PASSWORD_MODAL,
  showQuickPayTipInPasswordModal,
})

/**
 * 生成支付ID
 */
export const generatePaymentID = () => {
  return (dispatch) => {
    const state = store.getState()
    const timeStamp = String(new Date().valueOf())
    const uid = state.getIn(['orderDetail', 'uid'])
    const paymentId = timeStamp + uid

    dispatch(goSetSavePaymentId(paymentId))
    return paymentId
  }
}

/**
 * 保存支付id
 */
export const goSetSavePaymentId = (paymentId) => ({
  type: actions.SET_SAVE_PAYMENT_ID,
  paymentId,
})
/**
 * 额度不足弹框action
 */
function limitOutCreditAction() {
  const translate = Translate.getInstance().translate
  return globalActionCreator.toggleAlertDialog(true, {
    title: translate('额度不足弹框title'),
    content: (
      <div
        dangerouslySetInnerHTML={{
          __html: translate(
            '抱歉，您当前可用额度不足，请前往akulaku APP进行提额（Credit/Bill页->Naikkan Limit），之后即可进行分期购物。',
            {
              strong: styles.limitOutCreditDialogStrongText,
            },
          ),
        }}
      />
    ),
    footer: translate('我知道了'),
  })
}

// 设置还款日
export const setRepaymentDay = (repaymentDay) => ({
  type: actions.SET_REPAYMENT_DAY,
  repaymentDay,
})

// 设置还款日
export const setMerchantName = (merchantName) => ({
  type: actions.SET_MERCHANT_NAME,
  merchantName,
})

// 设置汇率信息
export const setExchangeRateVO = (exchangeRateVO) => ({
  type: actions.SET_EXCHANGE_RATE,
  exchangeRateVO,
})

// 设置商户优惠
export const setMerchantCouponVOS = (merchantCouponVOS) => ({
  type: actions.SET_MERCHANT_COUPON,
  merchantCouponVOS,
})
